body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.color_body{
  background-color:#f8f8f8;
  height:100vh;
}
.title{
  color:#224f61;
  font-size: 30px;
  font-weight: bold;
  line-height:64px;
}
.underline{
  height:1px;
  width: 200px;
  border-radius: 10px;
  border:2px solid #b45b35;
  background-color:#b45b35;
}
.my_btn{
  color:#8c8c8c;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  border:2px solid #b45b35;
  margin:20px 10px;
  transition: .3s;
  padding:3px 10px;
  border-radius: 8px;
}
.my_btn:hover{
  color:#fff;
  border-radius: 8px;
  border:2px solid #b45b35;
  background-color:#b45b35;
  border:none;
}

.img_item{
  height:100%;
  width: 252px;
  border-radius: 5px;
  border-bottom-right-radius:0 ;
  border-top-right-radius:0 !important;

}
.item_description{
  color:#8c8c8c;
  font-size: 16px;
  font-weight: bold;
  line-height:20px
}
.item_title{
  color:#555555;
  font-size:16px;
  font-weight: bold;
  line-height:20px;

}
.card_color{
  background-color:#f8f8f8;
}
.item_price{
  color:#b45b35;
  font-size: 16px;
  font-weight: bold;
  line-height:20px;

}
.btn_search{
  color:#b45b35;
  border: 3px solid #b45b35;
  border-radius: 8px;
  background:#fff;
  transition: .2s;

}
.btn_search:hover{
  color:#fff;
  border: 3px solid #fff;
  background:#b45b35;
}
.brand_color{
  color:#b45b35;
}

